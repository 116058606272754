<template>
  <div class="item" @click="handleGotoAuctionDetail">
    <img class="item-cover" :src="auction.cover" alt="" />
    <div class="item-main">
      <div class="B6 otext">{{ auction?.title || auction?.description }}</div>
      <div class="maxBidder" v-if="auction?.maxBidder">
        <div class="B8">最高价</div>
        <img class="avatar" :src="auction.maxBidder.avatar" alt="" />
        <div class="nickname H8 otext">
          {{ auction.maxBidder.nickname }}
        </div>
      </div>
    </div>
    <div :class="[{ price: true, hasBid: auction?.currentPrice }]">
      <div class="B7" v-if="auction.status === 1">{{ label }}</div>
      <div class="N5">
        ¥{{ auction?.currentPrice || auction.startingPrice }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, defineProps } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import jb from "@frontend/effuse";

const props = defineProps({
  auction: {
    type: Object,
    default: () => null,
  },
});
const label = computed(() => {
  const { auction } = props;
  if (auction.currentPrice) {
    return "当前价";
  }
  return "起拍价";
});
function handleGotoAuctionDetail() {
  if (window.isInFlutter) {
    jb.navigateTo({ url: `congress://product?tradeId=${props.auction.id}` });
  } else {
    router.push(`/product-detail?tradeId=${props.auction.id}`);
  }
}
</script>

<style lang="scss">
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16rpx;
  .item-cover {
    width: 88rpx;
    height: 88rpx;
    border-radius: 8rpx;
    object-fit: cover;
  }
  .item-main {
    flex: 1;
    width: 0;
  }
  .maxBidder {
    display: flex;
    align-items: center;
    gap: 8rpx;
    padding: 4rpx 0;
    margin-top: 4rpx;
    .avatar {
      width: 32rpx;
      height: 32rpx;
      border-radius: 50%;
      object-fit: cover;
    }
    .nickname {
      color: rgba(0, 0, 0, 0.5);
      width: 0;
      flex: 1;
    }
  }
  .price {
    text-align: right;
  }
  .hasBid {
    color: #ff8c42;
  }
}
</style>
