import { rpApi } from "@/http";
/* eslint-disable */
export default {
  getFieldDetail(params: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/field/detail", params);
  },
  getFieldAuctions(params: any) {
    return rpApi.post("/c2c-web/v1/auctioneer/auctions/field-auction", params);
  },
  getFieldTradeProfile(params: any) {
    return rpApi.post(
      "/c2c-web/v1/auctioneer/auctions/filed-trade-profile",
      params
    );
  },
};
